<template>
    <footer class="footer">
        <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2024069177号</a>
    </footer>
</template>

<script>
export default {
  name: 'SiteFooter'
}
</script>

<style scoped>
.footer {
  color: #fff;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  font-size: 12px;
}
</style>